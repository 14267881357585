// Google Fonts
// @import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,300,700,400italic);
@import "scrollbars";
@import "../stylesheets/partials/blog_cards";

html {
	font-family: 'Roboto', sans-serif;
	height: 100%;
}

body {
	background: #e4e5e6;
	font-size: 13px;
	line-height: 20px;
	margin: 0;
	height: 100%;
}

iframe {
	border-width: 0 !important;
}

// Modals
@import "partials/modal";

// Body
$font-stack: 'Roboto', sans-serif;
$main-background: #ffffff;

// Navigation
$hover-background: #E7E7E7;
$hover-text: #555;

body {
	background: $main-background;
}

.navbar-default .navbar-nav > li > a:hover {
	color: $hover-text;
	background-color: $hover-background;
}

.dropdown:hover .dropdown-menu {
	display: block;
}
